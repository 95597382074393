<template>
  <ion-page>
    <ion-header mode="md" class="ion-no-border">
      <ion-toolbar class="px-4">
        <ion-label class="fs-4 fw-bold" color="dark" slot="start">{{
          $t('competitor_price_feedback')
        }}</ion-label>
        <ion-icon slot="end" :icon="closeOutline" @click="$emit('closeModal')"></ion-icon>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid class="ion-no-padding form-submit-information">
        <ion-text class="mb-3">{{ $t('submit_information_about_other_supplier') }}</ion-text>
        <ion-row>
          <ion-col size="12" class="ion-no-padding">
            <ion-label class="fs-2">{{ $t('product_name') }}</ion-label>
            <div>
              <p>{{ skusSelected.sku }}</p>
            </div>
          </ion-col>
          <ion-col size="12" class="ion-no-padding mb-3">
            <ion-label class="fs-2 market-price-title">{{ $t('competitor_price') }}</ion-label>
            <div class="d-flex align align-center market-price-input">
              <ion-text>{{ currencySymbol }}</ion-text>
              <ion-input
                class="pr-2"
                type="number"
                placeholder="0.00"
                v-model="item.total_competitor_price"
                inputmode="decimal"
                onkeydown="javascript: return event.keyCode == 69 ? false : true"
                @ionChange="priceChange"
              ></ion-input>
            </div>
          </ion-col>
          <ion-col size="12" class="ion-no-padding mb-3">
            <ion-label class="fs-2">{{ $t('accountPage.supplier_name') }}</ion-label>
            <div>
              <ion-input
                type="text"
                v-model="item.supplier_name"
                :placeholder="$t('input_competitor_name')"
              ></ion-input>
            </div>
          </ion-col>
          <ion-col size="12" class="ion-no-padding my-1">
            <ion-label class="fs-2">{{ $t('other_information') }}</ion-label>
            <div>
              <ion-input
                type="text"
                v-model="item.other_infomation"
                @ionChange="otherInfoChange"
                :placeholder="$t('other_info_competitor')"
              ></ion-input>
              <ion-text v-if="errOtherInfo" class="fs-2" color="danger">{{
                $t('other_info_error')
              }}</ion-text>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid class="ion-no-padding mb-3">
        <ion-row class="my-1" v-if="nameFile">
          <div class="form-image" v-for="(item, idx) in fileDisplayed" :key="idx">
            <div class="row">
              <div class="title">
                <img class="ml-1" :src="item.image" />
                <ion-label class="ml-1">{{ item.name.substring(0, 10) }}...</ion-label>
              </div>
              <div class="form-btn">
                <ion-button size="small" @click="handleDeleteFile(idx)" color="danger">{{
                  $t('delete')
                }}</ion-button>
                <ion-button size="small" @click="handleChangeFile(item)">{{ $t('change') }}</ion-button>
              </div>
            </div>
            <div v-if="item.isReachMaxSize" class="margin-justify">
              <ion-text color="danger">{{ $t('file_upload_error') }}</ion-text>
            </div>
          </div>
        </ion-row>
      </ion-grid>
      <ion-input
        placeholder=""
        type="file"
        accept="image/*"
        capture="camera"
        @ionInput="onPhotoChanged"
        ref="photoInput"
        v-model="photoInput"
        class="d-none"
      >
      </ion-input>
      <ion-input
        placeholder=""
        type="file"
        accept=".xlsx,.xls,.pdf"
        @ionInput="onDocumentChanged"
        ref="documentInput"
        v-model="photoInput"
        class="d-none"
      >
      </ion-input>
    </ion-content>
    <ion-action-sheet
      mode="ios"
      :is-open="isOpenRef"
      css-class="my-custom-class"
      :buttons="buttons"
      @didDismiss="setOpen(false)"
    >
    </ion-action-sheet>
    <!-- Loading  -->
    <ion-loading
      :is-open="isLoading"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
      @didDismiss="setOpenLoading(false)"
    >
    </ion-loading>
    <ion-toast
      :is-open="isOpenToast"
      mode="ios"
      color="dark"
      :message="message"
      :duration="2000"
      position="top"
    >
    </ion-toast>

    <ion-footer mode="md" class="ion-no-border">
      <ion-toolbar>
        <ion-row class="form-submit">
          <ion-col size="12">
            <ion-button expand="block" fill="outline" @click="setOpen(true)"
              ><ion-icon :icon="addOutline"></ion-icon>{{ $t('add_file') }}</ion-button
            >
          </ion-col>
          <ion-col size="12">
            <ion-button
              expand="block"
              :color="isDiableSendFeedback ? 'medium' : 'primary'"
              :disabled="isDiableSendFeedback"
              @click="handleSendFeedBack"
              >{{ $t('send_feedback') }}</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import excel from '@/assets/images/newdesign/excel.png';
import pdf from '@/assets/images/newdesign/pdf.jpg';
import { apolloClient } from '@/main';
import { saleAddPriceFeedback } from '@/modules/sale/services/graphql';
import { fileToBase64 } from '@/modules/sale/services/libs/image';
import { errorAlert } from '@/utils/alert';
import { addOutline, closeOutline } from 'ionicons/icons';
import { ref } from 'vue';
export default {
  props: ['currencySymbol', 'skusSelected', 'selectedCompany'],
  setup() {
    const isLoading = false;
    const setOpenLoading = (state) => (isLoading.value = state);
    const isOpenRef = ref(false);
    const errOtherInfo = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);
    const reRender = ref(1);
    const isOpenToast = ref(false);
    const message = ref('');
    const priceChange = (event) => {
      let value = (event.target.value = event.target.value.replace('-', ''));
      let pattern = /(\d+\.\d\d)/g;
      let regex = pattern.exec(value);
      let maxLength = /(\d{0,18})/g.exec(value);
      if (maxLength.input.length > 18) {
        event.target.value = maxLength[1];
      }
      if (regex) {
        event.target.value = parseFloat(regex[1]);
      }
    };
    const otherInfoChange = (event) => {
      let value = event.target.value;
      if (value.length >= 255) {
        errOtherInfo.value = true;
      } else {
        errOtherInfo.value = false;
      }
    };
    return {
      closeOutline,
      addOutline,
      isOpenRef,
      setOpen,
      excel,
      pdf,
      isLoading,
      reRender,
      isOpenToast,
      message,
      priceChange,
      setOpenLoading,
      otherInfoChange,
      errOtherInfo,
      errorAlert
    };
  },

  data() {
    return {
      imageUrl: '',
      nameFile: '',
      fileClassify: '',
      fileDisplayed: [],
      fileChanges: false,
      maxFileSize: process.env.VUE_APP_MAX_FILE_UPLOAD_SIZE,
      item: {
        sku_id: null,
        customer_id: null,
        total_competitor_price: null,
        supplier_name: null,
        other_infomation: null,
        documents: []
      },
      buttons: [
        {
          text: this.$t('open_document'),
          handler: () => {
            this.triggerUploadDocument();
          }
        },
        {
          text: this.$t('Photo'),
          handler: () => {
            this.triggerUploadImage();
          }
        },
        {
          text: this.$t('cancel'),
          role: 'cancel'
        }
      ]
    };
  },
  mounted() {
    this.item.sku_id = this.skusSelected.sku_id;
    this.item.customer_id = this.selectedCompany.id;
  },
  computed: {
    isDiableSendFeedback() {
      return !this.item.total_competitor_price || !this.item.supplier_name ? true : false;
    }
  },
  methods: {
    async triggerUploadImage() {
      const ionInputEle = this.$refs.photoInput.$el;
      const inputEle = await ionInputEle.getInputElement();
      inputEle.click();
    },
    async triggerUploadDocument() {
      const ionInputEle = this.$refs.documentInput.$el;
      const inputEle = await ionInputEle.getInputElement();
      inputEle.click();
    },
    fileSize(size) {
      return size / 1024 ** 2;
    },
    async onPhotoChanged(event) {
      const files = event.target.querySelector('input').files;
      if (!files.length) return;
      if (!files[0].type.includes('image')) return;
      const findExistingFile = this.fileDisplayed.findIndex((item) => item.name === this.fileChanges.name);
      const fileBase64 = await fileToBase64(files[0]);
      this.imageUrl = fileBase64;
      this.nameFile = files[0].name;
      this.fileClassify = 'image';
      const size = this.fileSize(files[0].size);
      const file = {
        name: files[0].name,
        image: this.imageUrl,
        isReachMaxSize: size > this.maxFileSize ? true : false
      };
      if (files.length > 0) {
        if (findExistingFile !== -1) {
          this.fileDisplayed.splice(findExistingFile, 1);
          this.item.documents.splice(findExistingFile, 1);
          this.fileDisplayed = [file, ...this.fileDisplayed];
          this.item.documents.unshift(fileBase64);
        } else {
          this.fileDisplayed = [...this.fileDisplayed, file];
          this.item.documents.push(fileBase64);
        }
      }
    },
    async onDocumentChanged(event) {
      const files = event.target.querySelector('input').files;
      if (!files.length) return;
      const findExistingFile = this.fileDisplayed.findIndex((item) => item.name === this.fileChanges.name);
      const fileBase64 = await fileToBase64(files[0]);
      const fileName = files[0].name.slice(-1);
      fileName === 'x' || fileName === 's' ? (this.imageUrl = excel) : (this.imageUrl = pdf);
      this.nameFile = files[0].name;
      this.fileClassify = 'document';
      const size = this.fileSize(files[0].size);
      const file = {
        name: files[0].name,
        image: this.imageUrl,
        isReachMaxSize: size > this.maxFileSize ? true : false
      };
      if (files.length > 0) {
        if (findExistingFile !== -1) {
          this.fileDisplayed.splice(findExistingFile, 1);
          this.item.documents.splice(findExistingFile, 1);
          this.fileDisplayed = [file, ...this.fileDisplayed];
          this.item.documents.unshift(fileBase64);
        } else {
          this.fileDisplayed = [...this.fileDisplayed, file];
          this.item.documents.push(fileBase64);
        }
      }
    },
    handleChangeFile(file) {
      this.fileChanges = file;
      this.fileClassify === 'document' ? this.triggerUploadDocument() : this.triggerUploadImage();
    },
    handleDeleteFile(idx) {
      this.item.documents.splice(idx, 1);
      this.fileDisplayed.splice(idx, 1);
    },
    async handleOpenLoading(params) {
      this.reRender++;
      this.isLoading = params;
    },
    async handleOpenToast(params) {
      this.reRender++;
      this.isOpenToast = params;
    },
    async handleSendFeedBack() {
      try {
        await this.handleOpenLoading(true);
        await apolloClient.mutate({
          mutation: saleAddPriceFeedback,
          variables: {
            item: this.item
          }
        });
        this.message = this.$t('feedback');
        await this.handleOpenLoading(false);
        await this.handleOpenToast(true);
        setTimeout(() => {
          this.$emit('closeModal');
        }, 500);
      } catch (e) {
        await this.handleOpenLoading(false);
        this.errorAlert(e.message);
      }
    }
  }
};
</script>

<style src="./style.scss" lang="scss" scoped>
ion-content::part(scroll) {
  overflow: hidden;
}
</style>
