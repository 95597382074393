<template>
  <ion-page>
    <ion-header mode="md" class="ion-no-border">
      <ion-toolbar class="header-modal-update-price px-3">
        <ion-label class="fs-4 fw-bold" color="dark" slot="start">{{ $t('quote_price') }}</ion-label>
        <ion-icon slot="end" :icon="closeOutline" @click="closeQuotePrice"></ion-icon>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid class="mx-4 ion-no-padding mt-4">
        <div class="d-flex sku-title">
          <td-image
            class="img-update-price"
            :image="item?.image"
            :imagePath="IMAGE_PATH.PRODUCT"
            :imageDefault="DEFAULT_PRODUCT_IMAGE"
          ></td-image>
          <div class="sku-name ml-2">
            <ion-label class="label-tenant mt-2"> {{ item?.tenant?.tenant?.name }} </ion-label>
            <ion-label class="title mt-1">{{ skusUpdatePriceSelected.sku }}</ion-label>
            <div class="mt-1">
              <ion-label v-if="item?.halal === true" class="label-halal">{{
                $t('sync_state.halal')
              }}</ion-label>
              <ion-label v-if="item?.halal === false" class="label-non-halal">{{
                $t('sync_state.non_halal')
              }}</ion-label>
            </div>
          </div>
        </div>
        <div class="sku-info mt-2">
          <ion-row>
            <ion-col size="6" class="ion-no-padding">
              <ion-label>{{ $t('packaging_size') }}</ion-label>
            </ion-col>
            <ion-col size="6" class="ion-no-padding">
              <ion-label>{{ $t('weight') }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-no-padding">
              <ion-text>{{ packagingSize }}</ion-text>
            </ion-col>
            <ion-col size="6" class="ion-no-padding">
              <ion-text>{{ weight ? weight + 'kg' : '' }}</ion-text>
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>
      <div class="bg-gray my-1"></div>
      <ion-grid class="ion-no-padding mx-4 mt-1">
        <div v-if="!isShowDirectPriceQuoted">
          <div class="d-flex justify-space-between align-center limit-price mt-1">
            <ion-label>{{ $t('limit_price') }}</ion-label>
            <div>
              <ion-text>{{ handleShowLimitPrice(skusUpdatePriceSelected.limit_price) }}&nbsp;</ion-text>
              <ion-text>{{ `(${handleShowPriceKg(skusUpdatePriceSelected.limit_price, weight)})` }}</ion-text>
            </div>
          </div>
          <div class="d-flex justify-space-between align-center limit-price mt-1">
            <ion-label>{{ $t('last_quoted_price') }}</ion-label>
            <div>
              <ion-text>{{ item?.date_quoted ? price + ' ' : '-' }}</ion-text>
              <ion-text>{{
                item?.date_quoted ? `(${handleShowPriceKg(skusUpdatePriceSelected.price, weight)})` : ''
              }}</ion-text>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="d-flex justify-space-between align-center limit-price mt-1">
            <ion-label>{{ $t('direct_price') }}</ion-label>
            <div>
              <ion-text>{{ handleShowLimitPrice(skusUpdatePriceSelected.direct_price) }}&nbsp;</ion-text>
              <ion-text>{{
                `(${handleShowPriceKg(skusUpdatePriceSelected.direct_price, weight)})`
              }}</ion-text>
            </div>
          </div>
          <div class="d-flex justify-space-between align-center limit-price mt-1">
            <ion-label>{{ $t('limit_price') }}</ion-label>
            <div>
              <ion-text>{{ handleShowLimitPrice(skusUpdatePriceSelected.limit_price) }}&nbsp;</ion-text>
              <ion-text>{{ `(${handleShowPriceKg(skusUpdatePriceSelected.limit_price, weight)})` }}</ion-text>
            </div>
          </div>
        </div>
        <div class="d-flex justify-space-between align-center total-new-price mt-1">
          <ion-label>{{ $t('total_new_price') }}</ion-label>
          <div>
            <ion-text>{{ priceUpdateStr }}</ion-text>
          </div>
        </div>
      </ion-grid>
      <div class="bg-gray my-1"></div>
      <ion-grid class="ion-no-padding mx-4 mt-1 new-price">
        <ion-label>{{ $t('set_new_price') }}:</ion-label>
        <ion-row class="form-input mt-2">
          <ion-col>
            <div class="d-flex align-center input">
              <ion-text>{{ currencySymbol }}</ion-text>
              <ion-input
                v-model="price_kg"
                type="number"
                onkeydown="javascript: return event.keyCode == 69 ? false : true"
                placeholder="0.00"
                inputmode="decimal"
                @ionChange="setTwoDecimal"
              ></ion-input>
            </div>
          </ion-col>
          <ion-col size="6" class="ml-2 d-flex align-center form-radio">
            <ion-text class="mx-2">per</ion-text>
            <ion-radio-group value="true" mode="md" class="mr-1">
              <ion-radio slot="start" value="true"></ion-radio>
            </ion-radio-group>
            <ion-text>Kg</ion-text>
            <!-- <ion-radio-group value="true" mode="md" class="mx-2">
                  <ion-radio slot="start" value="false"></ion-radio>
                </ion-radio-group>
                <ion-text>{{ $t('per_unit') }}</ion-text> -->
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer class="ion-no-border">
      <ion-grid>
        <ion-row>
          <ion-col size="12" class="btn-submit my-1">
            <ion-button
              mode="md"
              :class="needRequestApproval ? 'btn-request-approve' : ''"
              :disabled="isDisableBtn"
              shape="round"
              expand="full"
              @click="saleUpdatePrice"
              >{{ !needRequestApproval ? $t('update_price') : $t('request_approve') }}</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
    <ion-loading
      mode="ios"
      :is-open="isOpenLoadingRef"
      cssClass="my-custom-class"
      :message="`${$t('please_wait')}. . .`"
      @didDismiss="setOpenLoading(false)"
    >
    </ion-loading>
  </ion-page>
</template>
<script>
import { DEFAULT_PRODUCT_IMAGE, IMAGE_PATH } from '@/modules/b2b/constants';
import { displayPrice } from '@/modules/sale/services/libs/helper';
import { ACTIONS as ACTIONS_SALE } from '@/modules/sale/store/product/actions';
import { MUTATIONS } from '@/modules/sale/store/product/mutations';
import { useCheck } from '@/modules/shared/utils/';
import { priceFormatter } from '@/utils/';
import { alertController, toastController } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';
import { computed, defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
const {
  mapGetters: mapGettersProduct,
  mapActions: mapActionsProduct,
  mapMutations: mapMutationsProduct
} = createNamespacedHelpers('sale/product');

export default defineComponent({
  props: [
    'item',
    'skusSelected',
    'selectedCompany',
    'weight',
    'currencySymbol',
    'isShowDirectPriceQuoted',
    'productId',
    'skuIds',
    'tenantId',
    'price'
  ],
  emits: ['closeModal', 'onUpdateProductDetails'],
  setup(props) {
    const skusUpdatePriceSelected = ref(props.skusSelected);
    const price_kg = ref(null);
    const isDisableBtn = ref(true);
    const isNewBiggerPrice = ref(false);
    const isBlockQuotePrice = ref(false);
    const { checkWeightItem, combineTwoUnit, isItemKGAndNoneUnit } = useCheck();
    const packagingSize = computed(() => {
      if (skusUpdatePriceSelected.value) {
        const { unit_amount, uom, unit_per_item, unit_per_item_uom } = skusUpdatePriceSelected.value;
        if (isItemKGAndNoneUnit(uom, unit_per_item)) {
          return '';
        }
        const itemsInProduct = checkWeightItem(unit_amount, uom);
        const unitsInItem = checkWeightItem(unit_per_item, unit_per_item_uom);
        return combineTwoUnit(itemsInProduct, unitsInItem);
      }
      return '';
    });
    const setTwoDecimal = (event) => {
      let value = event.target.value;
      value.length === 0 ? (isBlockQuotePrice.value = true) : (isBlockQuotePrice.value = false);
      let pattern = /(\d+\.\d\d)/g;
      let regex = pattern.exec(value);
      let maxLength = /(\d{0,18})/g.exec(value);
      if (maxLength.input.length > 18) {
        event.target.value = maxLength[1];
      }
      if (regex) {
        event.target.value = parseFloat(regex[1]);
      } else {
        return false;
      }
    };
    const openToast = async (message, mode, color = 'dark', position = 'bottom') => {
      const toast = await toastController.create({
        message,
        position,
        color,
        mode: 'ios',
        duration: 2000,
        cssClass: 'toastAfterHeader'
      });
      return toast.present();
    };
    const isOpenLoadingRef = ref(false);
    const setOpenLoading = async (state) => (isOpenLoadingRef.value = state);

    return {
      closeOutline,
      skusUpdatePriceSelected,
      packagingSize,
      price_kg,
      setTwoDecimal,
      isBlockQuotePrice,
      isDisableBtn,
      isNewBiggerPrice,
      openToast,
      isOpenLoadingRef,
      setOpenLoading,
      IMAGE_PATH,
      DEFAULT_PRODUCT_IMAGE
    };
  },
  data() {
    return {
      priceUpdateStr: '-.--',
      priceUpdate: 0
    };
  },

  computed: {
    ...mapGettersProduct(['item', 'status', 'error', 'listCache']),
    needRequestApproval() {
      return this.skusUpdatePriceSelected.limit_price > this.priceUpdate;
    }
  },

  methods: {
    ...mapActionsProduct([ACTIONS_SALE.SALE_UPDATE_PRICE]),
    ...mapMutationsProduct([MUTATIONS.UPDATE_PRICE_WITHOUT_RELOAD]),

    closeQuotePrice() {
      this.$emit('closeModal');
    },
    async showAlert({ header, message }) {
      const alert = await alertController.create({
        mode: 'ios',
        header,
        message,
        buttons: [
          {
            text: this.$t('close'),
            handler: () => {
              this.$emit('closeModal');
            }
          }
        ]
      });
      setTimeout(async () => await alert.present(), 200);
    },
    async sendUpdatePrice() {
      await this.setOpenLoading(true);
      await this[ACTIONS_SALE.SALE_UPDATE_PRICE]({
        payload: {
          customerBuyerId: this.selectedCompany.id,
          skuId: this.skusUpdatePriceSelected
            ? this.skusUpdatePriceSelected.sku_id
            : this.item?.skusData[0].sku_id,
          tenantId: this.item?.tenant_id,
          unitPrice: +this.price_kg,
          originalPrice: +this.skusUpdatePriceSelected.price,
          price: +this.priceUpdate,
          category: this.listCache
        }
      });
      await this.setOpenLoading(false);
      if (this.status && !this.needRequestApproval) {
        await this.$emit('onUpdateProductDetails');
        this.skusUpdatePriceSelected.price = this.priceUpdate;
        this.openToast(this.$t('price_updated') + '!');
        await this[MUTATIONS.UPDATE_PRICE_WITHOUT_RELOAD]();
        this.$emit('closeModal');
      } else {
        await this.showAlert({
          header: this.$t('quotationPendingTitle'),
          message: this.$t('quotationPendingContent')
        });
      }
    },
    async saleUpdatePrice() {
      if (this.isBlockQuotePrice || Math.sign(+this.priceUpdate) === -1) {
        this.openToast(this.$t('invalid_price'), 'danger');
        return;
      }

      if (
        this.skusUpdatePriceSelected.direct_price > 0 &&
        this.skusUpdatePriceSelected.direct_price < this.priceUpdate
      ) {
        const alert = await alertController.create({
          mode: 'ios',
          header: this.$t('quote_price_great_direct_price_title'),
          message: this.$t('quote_price_great_direct_price_body'),
          backdropDismiss: false,
          buttons: [
            {
              text: this.$t('cancel')
            },
            {
              text: this.$t('quote_price_great_direct_price_agree'),
              handler: () => {
                this.sendUpdatePrice();
              }
            }
          ]
        });
        return alert.present();
      } else {
        this.sendUpdatePrice();
      }
    },
    handleShowLimitPrice(price) {
      return `${priceFormatter(this.currencySymbol, displayPrice(price))}`;
    },
    handleShowPriceKg(price, packaging) {
      return `${priceFormatter(this.currencySymbol, displayPrice(price / packaging))}/Kg`;
    }
  },

  watch: {
    price_kg() {
      // fix for rounding incorrect
      let newPrice = parseFloat(this.price_kg) * parseFloat(this.weight);
      newPrice = parseFloat(Math.round(Number(newPrice) * 100) / 100).toFixed(2);
      this.priceUpdate = newPrice;
      this.priceUpdateStr = isNaN(newPrice) ? '-.--' : newPrice;
      this.isNewBiggerPrice = newPrice >= this.skusUpdatePriceSelected?.limit_price;
    },
    priceUpdate() {
      if (isNaN(this.priceUpdate) || this.priceUpdate == 0 || this.price_kg === null) {
        this.isDisableBtn = true;
      } else {
        this.isDisableBtn = false;
      }
    }
  }
});
</script>
<style src="../style.scss" lang="scss" scoped></style>
