<template>
  <div class="title">
    <ion-label class="ion-text-start">{{ $t('product_detail.similar_product') }}</ion-label>
  </div>
  <div v-for="(item, index) in productItems" :key="index" @click="seeSimiliarProduct(item)">
    <NewProductItem :key="item.id" :item="item" :user="user" :selectedCompany="selectedCompany" />
  </div>
  <div class="title pt-2 mb-4">
    <ion-button
      class="button"
      fill="outline"
      expand="block"
      size="small"
      @click="$emit('explore-other-product')"
      >{{ $t('product_detail.explore_other_product') }}</ion-button
    >
  </div>
</template>
<script>
import NewProductItem from '@/components/product-item/NewCardProductItem.vue';
import { discountFn, priceDiscountFn, priceFn, weightFn } from '@/modules/b2b/services/libs/home';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  inject: ['$storage'],
  emits: ['select-similar-product', 'explore-other-product'],
  components: {
    NewProductItem
  },
  props: {
    productItems: {
      type: [Object],
      default: new Array([])
    },
    user: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      listImageSrc: {}
    };
  },
  setup() {
    const currencySymbol = ref(null);
    const selectedCompany = ref(null);
    return {
      currencySymbol,
      selectedCompany,
      priceFn,
      weightFn,
      priceDiscountFn,
      discountFn
    };
  },
  async mounted() {
    this.selectedCompany = await this.$storage.getSelectedCompany();
    this.currencySymbol = this.user && this.user.country.currency_symbol;
  },

  methods: {
    seeSimiliarProduct(itemData) {
      this.$emit('select-similar-product', itemData);
    },
    onImageSetted(opts) {
      this.listImageSrc[opts.image] = opts.imageSrc;
    }
  }
});
</script>
<style lang="scss" scoped>
.title {
  background: whitesmoke;
  font-weight: bold;
  font-size: 16px;
  padding: 16px;
}
</style>
