<template>
  <product-detail-skeleton v-if="!isShow && !opacity" />
  <ion-page class="detail-product" v-else>
    <ion-header
      class="ion-no-border"
      mode="ios"
      :class="['header-product', isShowHeader || !isOnline ? 'animation' : '']"
    >
      <ion-toolbar class="toolbar-header pl-1 pr-2">
        <ion-icon @click="backHome" size="large" slot="start" :icon="chevronBackOutline"></ion-icon>
        <ion-icon slot="end" size="large" :icon="shareSocialOutline" @click="openSocialSharing"></ion-icon>
        <ion-label class="label-header-product">{{ item.name }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <!-- content -->
    <ion-content
      ref="productDetailContent"
      class="modal-content"
      :scroll-events="true"
      @ionScroll="handleShowHeader($event)"
      :id="`product-details-${item?.id}`"
    >
      <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-card v-if="!isOnline" class="mx-0 mt-5rem px-3 py-3 shadow border-rad-0">
        <ion-card-content class="pa-0">
          <div class="text-center mt-5">
            <ion-text class="fs-2">{{ $t('page_taking_longer') }}</ion-text>
            <div class="mt-4 d-flex column-center">
              <ion-label class="fw-600 mb-2">{{ $t('pull_to_refresh') }}</ion-label>
              <ion-icon color="primary fs-2rem" :icon="arrowDownOutline"></ion-icon>
            </div>
          </div>
        </ion-card-content>
      </ion-card>
      <div v-if="isOnline">
        <div
          style="margin-top: 3.1rem"
          v-show="!isShowHeader"
          :class="['back-btn', !isShowHeader && 'hidden-back']"
        >
          <ion-icon :icon="chevronBackOutline" @click="backHome"></ion-icon>
          <ion-icon :icon="shareSocialOutline" @click="openSocialSharing"></ion-icon>
        </div>
        <div class="product-image">
          <td-image
            class="no-image-wrapper"
            :image="item?.image"
            :imagePath="IMAGE_PATH.PRODUCT"
            :imageDefault="DEFAULT_PRODUCT_IMAGE"
          ></td-image>
          <div v-if="isProductOutOfStockRef" class="blend-img"></div>
          <div v-if="isProductOutOfStockRef" class="image-off-oss"></div>
          <div v-if="isProductOutOfStockRef" class="centered-oos-label">
            <p class="oos">{{ $t('out_of_stock') }}</p>
          </div>
        </div>
        <ion-grid class="px-4">
          <ion-row>
            <ion-col size="12" class="ion-no-padding mt-2">
              <ion-label class="label-tenant">{{ item.tenant.tenant.name }}</ion-label>
              <ion-label
                class="label-product"
                :style="{ 'font-size': fontSize + 'px', 'line-height': lineHeight + 'px' }"
                ref="refNameProduct"
                >{{ item.name }}
              </ion-label>
              <ion-label v-if="item?.halal === true" class="label-halal">{{
                $t('sync_state.halal')
              }}</ion-label>
              <ion-label v-if="item?.halal !== null && item?.halal === false" class="label-non-halal">{{
                $t('sync_state.non_halal')
              }}</ion-label>
            </ion-col>
            <ion-col v-if="allowAttach" size="12" class="ion-no-padding">
              <ion-label class="label-price" color="primary">{{
                price === `${currencySymbol} NaN` ? '' : price
              }}</ion-label>

              <ion-label class="label-kg mb-2">
                {{ price !== `${currencySymbol} NaN` && price ? priceKgBelowTotalPrice : '' }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-no-padding">
              <ion-label class="label-pack mt-3">{{ $t('packaging_size') }}</ion-label>
              <ion-label class="label-weight">{{ packagingSize }}</ion-label>
            </ion-col>
            <ion-col size="6" class="ion-no-padding">
              <ion-label class="label-pack mt-3">{{ $t('weight') }}</ion-label>
              <ion-label class="label-weight">{{ weight ? weight + ' ' + 'kg' : '' }}</ion-label>
              <tooltip v-if="skusSelected?.is_catch_weight" />
            </ion-col>
            <ion-col size="12" class="mt-2 ion-no-padding">
              <ion-label class="label-pack mt-3">{{ $t('description') }}</ion-label>
              <ion-label ref="refDescription" :class="['label-weight', isShowMore ? 'overflow' : '']"
                >{{ item.description ? item.description : '' }}
              </ion-label>
              <ion-label v-if="isDisplayShowMore" @click="handleShowMore" class="label-showmore">{{
                isShowMore ? $t('show_more') : $t('show_less')
              }}</ion-label>
            </ion-col>
            <ion-col size="12" class="mt-2 ion-no-padding">
              <ion-label class="label-pack mt-3">{{ $t('category_sub') }}</ion-label>
              <div class="form-category my-1 mb-2">
                <ion-label class="label-category-main">{{ item.categories?.main_categories.name }}</ion-label>
                <ion-label class="label-category-sub ml-1">{{
                  item.categories?.sub_categories.name
                }}</ion-label>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div class="bg-gray"></div>
        <ion-grid class="px-4">
          <ion-row class="d-flex">
            <ion-col size="4" class="ion-no-padding">
              <ion-label class="label-pack" color="grey" v-if="!item.is_single_variant">{{
                $t('variations')
              }}</ion-label>
              <div>
                <ion-badge
                  slot="start"
                  class="oos-badge is-single-variant"
                  color="primary"
                  fill="outline"
                  v-if="
                    item.is_single_variant &&
                    item.skusData[0]?.is_purchased &&
                    !item.skusData[0]?.is_out_of_stock
                  "
                  >{{ $t('product_detail.buy_again') }}</ion-badge
                >
              </div>
            </ion-col>
            <ion-col size="8" class="ion-no-padding d-flex flex-column align-end">
              <ion-row
                class="ion-align-items-end"
                v-if="item.is_single_variant && item.skusData[0]?.is_out_of_stock"
              >
                <ion-text class="quantity">{{ $t('product_detail.stock') }}:</ion-text>
                <ion-text class="quantity ml-1" color="danger">{{
                  $t('product_detail.out_of_stock')
                }}</ion-text>
              </ion-row>
              <ion-row class="ion-align-items-end" v-else>
                <ion-text class="quantity">{{ $t('Quantity') }}:</ion-text>
                <ion-text
                  v-if="!skusSelected?.is_order_by_weight"
                  class="quantity ml-1"
                  :color="isQuatityAndStock ? 'primary' : 'grey'"
                >
                  {{
                    isQuatityAndStock === null
                      ? '-'
                      : !isQuatityAndStock
                      ? '0'
                      : `${+quantity > 0 ? quantity : 0}` +
                        ' ' +
                        $t('in') +
                        ' ' +
                        stock +
                        ' ' +
                        $t('stock_locations')
                  }}
                </ion-text>
                <ion-text v-else class="quantity ml-1" :color="isQuatityAndStock ? 'primary' : 'grey'">
                  {{
                    isQuatityAndStock === null
                      ? '-'
                      : !isQuatityAndStock
                      ? '0'
                      : `${+quantity > 0 ? quantity : 0}` +
                        ' KG ' +
                        $t('in') +
                        ' ' +
                        stock +
                        ' ' +
                        $t('stock_locations')
                  }}
                </ion-text>
              </ion-row>
              <ion-row class="ion-align-items-end backorder">
                <ion-text class="quantity-black">{{ $t('back_order') }}:</ion-text>
                <ion-text
                  class="quantity mt-0 ml-1"
                  :color="isBackOrder === null ? 'grey' : isBackOrder ? 'primary' : 'danger'"
                >
                  {{
                    isBackOrder === null ? '-' : isBackOrder ? $t('Available') : $t('unavailable')
                  }}</ion-text
                >
              </ion-row>
            </ion-col>
          </ion-row>
          <ion-row class="mt-2" v-if="!item.is_single_variant">
            <ion-col
              class="ion-no-padding mt-1"
              size="12"
              v-for="(specs, keyName) in getSpecsByKey"
              :key="keyName"
            >
              <ion-row>
                <ion-label class="label-keyname">{{ keyName }}</ion-label>
              </ion-row>
              <ion-label class="label-select-another">{{
                allowAttach &&
                JSON.stringify(skusSelected?.specs).includes(`${keyName}`) &&
                specs.length > 1 &&
                isOutofStockRef
                  ? ' ' + $t('select_another_option')
                  : ''
              }}</ion-label>
              <div class="d-flex flex-wrap mt-1">
                <div v-for="spec in specs" :key="spec.value" class="form-btn mt-2">
                  <ion-badge
                    slot="start"
                    class="oos-badge is-oos"
                    color="medium"
                    v-if="showOosAndBuyAgainStatus(spec.value).showOos"
                    >{{ $t('product_detail.sold_out') }}</ion-badge
                  >
                  <ion-badge
                    slot="start"
                    class="oos-badge buy-again"
                    color="primary"
                    fill="outline"
                    v-if="showOosAndBuyAgainStatus(spec.value).showBuyAgain"
                    >{{ $t('product_detail.buy_again') }}</ion-badge
                  >
                  <ion-button
                    mode="ios"
                    v-if="specsSelected.includes(spec.value) && !showOosAndBuyAgainStatus(spec.value).showOos"
                    fill="outline"
                    class="variant-chip btn-primary"
                    @click="unCheckSpecItem(spec.value)"
                  >
                    {{ spec.value }}
                  </ion-button>
                  <ion-button
                    mode="ios"
                    class="variant-chip btn-disable"
                    v-else-if="
                      (specsInvolved.length > 0 && !specsInvolved.includes(spec.value)) ||
                      showOosAndBuyAgainStatus(spec.value).showOos
                    "
                    fill="outline"
                    disabled
                  >
                    {{ spec.value }}
                  </ion-button>
                  <ion-button
                    mode="ios"
                    v-else
                    class="variant-chip btn-grey"
                    fill="outline"
                    @click="checkSpecItem(spec.value)"
                  >
                    {{ spec.value }}
                  </ion-button>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div class="bg-gray"></div>
        <ion-grid class="px-4">
          <ion-row>
            <ion-col size="12" class="ion-no-padding feed-back">
              <div
                class="d-flex justify-space-between align-items py-4"
                @click="isDisableFeedBack ? '' : setOpenModalFeedBack(true)"
              >
                <ion-text>{{ $t('competitor_price_feedback') }}</ion-text>
                <div class="d-flex align-items">
                  <ion-text :color="isDisableFeedBack ? 'medium' : 'primary'">{{ $t('add') }}</ion-text>
                  <ion-icon
                    :color="isDisableFeedBack ? 'medium' : 'primary'"
                    :icon="chevronForwardOutline"
                  ></ion-icon>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div class="bg-gray"></div>
        <!-- display similar product items -->
        <div ref="similiarProduct" class="similiar-product-bg">
          <product-recommend-list
            v-if="(showSimiliarProduct || isProductOutOfStockRef) && similiarProductItems?.length > 0"
            :productItems="getSimiliarProductsData"
            :user="user"
            @select-similar-product="onSelectSimilarProduct"
            @explore-other-product="onExploreOtherProducts"
          ></product-recommend-list>
        </div>
      </div>
    </ion-content>
    <!-- footer -->
    <ion-footer v-if="isOnline" class="ion-no-border footer">
      <ion-toolbar class="ion-no-padding">
        <div>
          <ion-grid v-if="allowAttach" class="ion-no-padding">
            <ion-row class="footer-input" :class="{ 'mb-3': !checkDisableAddToCart }">
              <ion-col size="6" class="pl-4 mt-2 mb-1">
                <!-- <div>
                  <ion-label class="label-total-price">{{ $t('total_price') }}</ion-label>
                </div> -->
                <div class="d-flex align-center">
                  <ion-label class="label-total-price">{{ $t('total_price') }}</ion-label>
                  <ion-label class="label-special-price" v-if="isSpecialPrice">
                    {{ $t('special_price') }}</ion-label
                  >
                  <ion-label class="label-special-price" v-if="isDirectPrice">
                    {{ $t('direct_price') }}</ion-label
                  >
                </div>
                <div>
                  <ion-label v-if="!isOutofStockRef" class="label-sum-price">
                    <span v-if="skusSelected?.is_order_by_weight">
                      {{ showPriceWeight }}
                    </span>
                    <span v-else>
                      {{ showPriceQuantity }}
                    </span>
                  </ion-label>
                  <ion-label v-else class="label-sum-price">-</ion-label>
                </div>
                <div>
                  <ion-label v-if="!isOutofStockRef" class="label-price-kg">{{
                    priceKgBelowTotalPrice
                  }}</ion-label>
                  <ion-label v-else class="label-price-kg">-</ion-label>
                </div>
              </ion-col>
              <ion-col size="6" class="d-flex align-items-center pr-4 mt-2 mb-1">
                <div v-if="skusSelected?.is_order_by_weight" class="d-flex align-self-center">
                  <ion-input
                    type="number"
                    :readonly="isOutofStockRef && allowAttach"
                    class="input-weight"
                    :value="formattedWeight"
                    @ionInput="onInputWeight"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || (event.target.value.indexOf('.') === -1 && event.charCode === 46)"
                  />
                  <span class="ml-1 mt-2">{{ $t('kg') }}</span>
                </div>
                <div class="d-flex cnt-weight h-100 form-input" v-else>
                  <div class="align-self-center text-center">
                    <div v-if="!isOutofStockRef" class="cnt-icon-q mr-2" @click="minusQuantity">
                      <ion-icon class="icon-q" :icon="remove"></ion-icon>
                    </div>
                    <div v-else class="cnt-icon-q-outline mr-2" @click="minusQuantity">
                      <ion-icon class="icon-q" :icon="removeCircleOutline"></ion-icon>
                    </div>
                  </div>
                  <div v-if="!isOutofStockRef" size="5" class="align-self-center text-center">
                    <input
                      v-model.number="orderQuantity"
                      type="number"
                      maxlength="4"
                      class="input-weight"
                      @input="orderQuantityChange"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    />
                  </div>
                  <div v-else size="5" class="align-self-center text-center">
                    <ion-input readonly v-model.number="outOfStockQty" type="text" class="input-oos" />
                  </div>
                  <div class="align-self-center text-center">
                    <div
                      v-if="!isOutofStockRef"
                      class="ml-2"
                      :class="{
                        'cnt-icon-q-disabled': disabledAddQuantity,
                        'cnt-icon-q': !disabledAddQuantity
                      }"
                      @click="addQuantity"
                    >
                      <ion-icon class="icon-q" :icon="add"></ion-icon>
                    </div>
                    <div v-else class="cnt-icon-q-outline ml-2" @click="addQuantity">
                      <ion-icon :icon="addCircleOutline"></ion-icon>
                    </div>
                  </div>
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="7" />
              <ion-col v-if="checkDisableAddToCart" size="5" class="ion-no-padding">
                <ion-text color="danger">{{
                  $t('soldInUnits', { number: skusSelected.increment_qty })
                }}</ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
          <div class="d-flex align-center form-submit mb-2">
            <!-- quoted price button -->
            <ion-button
              fill="outline"
              tabindex="-1"
              class="btn-add-to-cart mr-0 ml-3"
              :disabled="!allowAttach"
              :color="!colorGrayQuoteBtn ? 'primary' : 'medium'"
              expand="block"
              @click="setOpen(true)"
            >
              {{ $t('quote_price') }}
            </ion-button>

            <!-- add to cart -->
            <div class="flex-grow-1 btn-add-to-card">
              <ion-button
                v-if="!isOutofStockRef"
                tabindex="-1"
                class="btn-add-to-cart"
                :class="allowAttach && 'mr-0'"
                :disabled="
                  checkDisableAddToCart ||
                  isDisabledButtonByWeight ||
                  isOutofStockRef ||
                  !allowAttach ||
                  disableOrderByWeight ||
                  checkZeroPrice
                "
                :color="!colorGrayBtn ? 'primary' : 'medium'"
                expand="block"
                @click="handleCheckLatetestPrice"
              >
                <span class="ml-1" v-if="!isAddToCartLoading">{{
                  allowAttach ? $t('add_to_cart') : $t('select_variation')
                }}</span>
                <ion-spinner name="crescent" v-else></ion-spinner>
              </ion-button>

              <!-- select another variant button -->
              <!-- <ion-button
                v-if="isOutofStockRef && oosList?.oosList?.otherVariant.length > 0"
                tabindex="-1"
                class="btn-add-to-cart mr-0"
                fill="solid"
                color="primary"
                expand="block"
                @click="setOtherVariantModal(true)"
              >
                <span class="ml-1"> {{ $t('select_other_variant') }}</span>
              </ion-button> -->

              <!-- oos stock button -->
              <ion-button
                v-if="isOutofStockRef"
                tabindex="-1"
                class="btn-add-to-cart mr-0"
                fill="solid"
                :disabled="true"
                color="medium"
                expand="block"
              >
                <span v-if="!checkOtherVariation" class="ml-1"> {{ $t('out_of_stock') }}</span>
                <ion-spinner v-else name="dots"></ion-spinner>
              </ion-button>
            </div>

            <!-- favorite -->
            <div>
              <btn-favorite
                :isFavorite="skusSelected?.is_favorite"
                :selectedCompany="selectedCompany"
                :skusSelected="skusSelected"
                :allowAttach="allowAttach"
                @update-local-favorite="updateLocalFavorite"
              />
            </div>
          </div>
        </div>
      </ion-toolbar>
    </ion-footer>

    <ion-modal
      ref="modal"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
      mode="ios"
      css-class="default-bottom-sheet-modal"
      :is-open="isOpenRef"
      @didDismiss="setOpen(false)"
    >
      <QuotePrice
        :currencySymbol="currencySymbol"
        :selectedCompany="selectedCompany"
        :skusSelected="skusSelected"
        :weight="weight"
        :isShowDirectPriceQuoted="isShowDirectPriceQuoted"
        :item="item"
        :productId="productId"
        :skuIds="skuIds"
        :tenantId="tenantId"
        :price="price"
        @closeModal="setOpen(false)"
        @onUpdateProductDetails="reloadProductDetails"
      />
    </ion-modal>
    <ion-modal
      ref="modal"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
      mode="ios"
      css-class="default-bottom-sheet-modal"
      :is-open="isOpenModalFeedBack"
      @didDismiss="setOpenModalFeedBack(false)"
    >
      <ModalFeedBack
        @closeModal="setOpenModalFeedBack(false)"
        :skusSelected="skusSelected"
        :currencySymbol="currencySymbol"
        :selectedCompany="selectedCompany"
      />
    </ion-modal>
    <ion-modal
      ref="modal-other-variant"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
      mode="ios"
      css-class="modal-instruction"
      :is-open="isOpenSelectOtherVariant"
      @didDismiss="setOtherVariantModal(false)"
    >
      <SelectOtherVariant
        @closeSelectOtherVariantModal="setOtherVariantModal(false)"
        :getSpecsOosByKey="getSpecsOosByKey"
        :getSpecsOosById="getSpecsOosById"
        :selectedCompany="selectedCompany"
        :user="user"
        :oosList="oosList"
        :currencySymbol="currencySymbol"
        :productId="productId"
        :tenantId="tenantId"
        :item="item"
        @updateSkusfavorite="changeSkuFavorite"
        @refreshFavoriteList="$emit('refresh-favorite-list')"
      />
    </ion-modal>
    <ion-modal
      mode="ios"
      backdrop-dismiss="false"
      :is-open="isOpenPriceUpdated"
      css-class="modal-price-updated"
      @didDismiss="setOpenModalPriceUpdate(false)"
    >
      <price-update-alert @close-modal="setOpenModalPriceUpdate(false)"></price-update-alert>
    </ion-modal>
  </ion-page>
</template>

<script>
import Tooltip from '@/components/molecules/tooltip/Tooltip.vue';
import { apolloClient } from '@/main';
import { DEFAULT_PRODUCT_IMAGE, IMAGE_PATH } from '@/modules/b2b/constants';
import { TYPE_SEARCH } from '@/modules/sale/constants';
import { saleGetCustomerDetail, saleGetLatestPrice } from '@/modules/sale/services/graphql';
import { displayPrice, getTomorrow } from '@/modules/sale/services/libs/helper';
import { ACTIONS as ACTIONS_CART } from '@/modules/sale/store/cart/actions';
import { ACTIONS as ACTIONS_NOTIFICATION } from '@/modules/sale/store/notification/actions';
import { ACTIONS as ACTIONS_SALE, ACTIONS as ACTION_OOS } from '@/modules/sale/store/product/actions';
import { MUTATIONS } from '@/modules/sale/store/product/mutations';
import ModalFeedBack from '@/modules/sale/views/product-detail/components/ModalFeedBack/index.vue';
import { INPUT_QUANTITY_BY_WEIGHT, MAX_INPUT } from '@/modules/shared/constants/';
import { isLargerMaxInput, isQuantityAMultipleOfIncrement, useCheck } from '@/modules/shared/utils/';
import CleverTap from '@/services/shared/helper/clevertap';
import { priceFormatter } from '@/utils/';
import { Device } from '@capacitor/device';
import { Share } from '@capacitor/share';
import { alertController, toastController, useBackButton } from '@ionic/vue';
import {
  add,
  addCircleOutline,
  arrowDownOutline,
  checkmarkOutline,
  chevronBackOutline,
  chevronForwardOutline,
  heart,
  paperPlaneOutline,
  remove,
  removeCircleOutline,
  shareSocialOutline,
  storefrontOutline
} from 'ionicons/icons';
import debounce from 'lodash.debounce';
import { computed, defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { createNamespacedHelpers } from 'vuex';
import PriceUpdateAlert from '../cart/components/PriceUpdateAlert.vue';
import BtnFavorite from './components/BtnFavorite.vue';
import ProductRecommendList from './components/ProductRecommendList.vue';
import QuotePrice from './components/QuotePrice.vue';
import SelectOtherVariant from './components/SelectOtherVariant.vue';
import ProductDetailSkeleton from './skeleton.vue';
import functions from './utils';

const {
  mapGetters: mapGettersProduct,
  mapActions: mapActionsProduct,
  mapMutations: mapMutationsProduct
} = createNamespacedHelpers('sale/product');
const { mapGetters: mapGettersOosProduct, mapActions: mapActionsOos } = createNamespacedHelpers(
  'sale/product'
);
const { mapGetters: mapGettersCart, mapActions: mapActionsCart } = createNamespacedHelpers('sale/cart');
const { mapActions: mapActionsNotification } = createNamespacedHelpers('sale/notification');
export default defineComponent({
  name: 'ModalProductDetail',

  components: {
    Tooltip,
    // components
    BtnFavorite,
    ModalFeedBack,
    ProductRecommendList,
    ProductDetailSkeleton,
    SelectOtherVariant,
    PriceUpdateAlert,
    QuotePrice
  },
  setup() {
    const user = ref(null);
    const tenantClassLevel = ref(null);
    const specsSelected = ref([]);
    const skusSelected = ref(null);
    const specsInvolved = ref([]);
    const skuIdsInvolved = ref([]);
    const selectedCompany = ref(null);
    const orderQuantity = ref(1);
    const outOfStockQty = ref(0);
    const orderWeight = ref(null);
    const isOpenRef = ref(false);
    const setOpen = (state) => {
      isOpenRef.value = state;
    };
    const isShowHeader = ref(false);
    const isShowMore = ref(true);
    const isDisplayShowMore = ref(false);
    const item = ref();
    const isChangeSkuFavorite = ref(false);
    const isOpenSelectOtherVariant = ref(false);
    const isOutofStockRef = ref(false);
    const setOutOfStock = (state) => {
      isOutofStockRef.value = state;
    };
    const isProductOutOfStockRef = ref(false);
    const setProductOutOfStock = (state) => {
      isProductOutOfStockRef.value = state;
    };
    const route = useRoute();
    const router = useRouter();
    //loading
    const isAddToCartLoading = ref(false);
    const isShow = ref(false);
    const checkOtherVariation = ref(false);
    const similiarProductItems = ref(null);
    // functions
    const changeSkusSelected = (state = { sku_id: 0 }) => {
      skusSelected.value = state;
      if (state.weight) {
        orderWeight.value = state.weight.toFixed(2);
        if (!state.is_back_order && state.total_available_quantity > 0) {
          orderWeight.value =
            state.weight > state.total_available_quantity
              ? state.total_available_quantity.toFixed(2)
              : state.weight.toFixed(2);
        }
      } else {
        orderWeight.value = 0;
      }
    };
    const isOpenModalFeedBack = ref(false);
    const setOpenModalFeedBack = async (state) => (isOpenModalFeedBack.value = state);
    const openToast = async (message, color = 'primary', position = 'top') => {
      const toast = await toastController.create({
        message,
        position,
        color,
        duration: 700
      });
      return toast.present();
    };

    const setOrderQuantity = (value) => {
      orderQuantity.value = value;
    };

    const { checkWeightItem, combineTwoUnit, isItemKGAndNoneUnit } = useCheck();
    const packagingSize = computed(() => {
      if (skusSelected.value) {
        const { unit_amount, uom, unit_per_item, unit_per_item_uom } = skusSelected.value;
        if (isItemKGAndNoneUnit(uom, unit_per_item)) {
          return '';
        }
        const itemsInProduct = checkWeightItem(unit_amount, uom);
        const unitsInItem = checkWeightItem(unit_per_item, unit_per_item_uom);
        return combineTwoUnit(itemsInProduct, unitsInItem);
      }
      return '';
    });

    const { getProductDetail } = functions.useGetProductDetail();
    const isOpenPriceUpdated = ref(false);
    const latesNewPrice = ref(null);
    const productId = ref(Number(route.params.id));
    const tenantId = ref();
    const buyerId = ref();
    useBackButton(10, () => {
      router.back();
    });

    return {
      // variable
      specsSelected,
      skusSelected,
      user,
      tenantClassLevel,
      orderQuantity,
      outOfStockQty,
      orderWeight,
      selectedCompany,
      specsInvolved,
      skuIdsInvolved,
      isOpenRef,
      setOpen,
      isShow,
      packagingSize,
      item,
      isOpenSelectOtherVariant,
      isOpenPriceUpdated,
      latesNewPrice,
      productId,
      tenantId,
      buyerId,
      // functions
      changeSkusSelected,
      openToast,
      getProductDetail,
      route,
      router,

      // icons
      arrowDownOutline,
      storefrontOutline,
      checkmarkOutline,
      paperPlaneOutline,
      heart,
      remove,
      add,
      alertController,
      chevronBackOutline,
      removeCircleOutline,
      addCircleOutline,
      shareSocialOutline,

      chevronForwardOutline,
      //loading
      isAddToCartLoading,
      checkOtherVariation,
      similiarProductItems,
      setOrderQuantity,
      isShowHeader,
      isShowMore,
      isDisplayShowMore,
      isOpenModalFeedBack,
      setOpenModalFeedBack,
      isChangeSkuFavorite,
      isOutofStockRef,
      setOutOfStock,
      Share,
      IMAGE_PATH,
      DEFAULT_PRODUCT_IMAGE,
      isOnline: ref(true),
      isProductOutOfStockRef,
      setProductOutOfStock
    };
  },
  data() {
    return {
      fontSize: 28,
      lineHeight: 33,
      height: 66,
      opacity: 0,
      oosVariable: {
        mainCategoryId: '',
        isSingleVariant: false,
        productId: null,
        tenantId: null,
        buyerId: null,
        limit: 5,
        offset: 0
      }
    };
  },
  inject: ['$storage'],
  async beforeRouteLeave(to, from, next) {
    const historyState = window.history.state;
    const prevHistoryPath = historyState?.back;
    // reset search params when user leave the product detail after add product to cart
    if (prevHistoryPath === '/sale/search-products' && to.path === '/sale/main/home') {
      await this.setSearchParamsBackToDefault();
    }
    next();
  },
  async ionViewDidEnter() {
    await this.getSaleProductDetail();
  },
  ionViewWillLeave() {
    if (this.showSimiliarProduct) {
      this.similiarProductItems = null;
    }
  },
  computed: {
    ...mapGettersProduct(['item', 'search', 'status', 'error', 'list', 'listCache', 'CachesaleUpdatePrice']),
    ...mapGettersOosProduct(['oosList']),
    ...mapGettersCart(['cart']),
    allProductOos() {
      return this.item.skusData.every((sku) => sku.is_out_of_stock);
    },
    showPriceQuantity() {
      return this.skusSelected
        ? priceFormatter(this.currencySymbol, displayPrice(this.skusSelected.price * this.orderQuantity))
        : priceFormatter(this.currencySymbol, displayPrice(0));
    },
    showPriceWeight() {
      return this.skusSelected
        ? priceFormatter(
            this.currencySymbol,
            displayPrice((this.skusSelected.price / this.skusSelected.weight) * Number(this.orderWeight))
          )
        : priceFormatter(this.currencySymbol, displayPrice(0));
    },
    weight() {
      return this.skusSelected ? this.skusSelected.weight : 0;
    },
    currencySymbol() {
      return this.user && this.user.country.currency_symbol;
    },
    originPrice() {
      return this.skusSelected && `${this.currencySymbol} ${displayPrice(this.skusSelected.origin_price)}`;
    },
    price() {
      return this.skusSelected?.sku_id
        ? `${priceFormatter(this.currencySymbol, displayPrice(this.skusSelected.price))}`
        : '-';
    },
    getSpecsByKey() {
      return functions.sortListStrings(functions.getSpecsByKey(this.item));
    },
    getSkusInfo() {
      return functions.getSkusInfo(this.item);
    },
    getSpecsOosByKey() {
      return functions.sortListStrings(functions.getSpecsOosByKey(this.oosList?.oosList?.otherVariant));
    },
    getSpecsOosById() {
      return functions.getSpecsOosById(this.oosList?.oosList?.otherVariant);
    },
    allowAttach() {
      return this.skusSelected && this.skusSelected.sku_id !== 0;
    },
    isDisabledButtonByWeight() {
      const weight = Number(this.orderWeight);
      const quantity = Number(this.orderQuantity);
      if (this.skusSelected?.is_order_by_weight) {
        return weight <= 0;
      }
      return quantity <= 0;
    },
    disableOrderByWeight() {
      return this.skusSelected?.is_order_by_weight && this.skusSelected?.total_available_quantity
        ? this.orderWeight > this.skusSelected?.total_available_quantity
        : false;
    },
    colorGrayBtn() {
      return (
        this.isDisabledButtonByWeight ||
        this.checkDisableAddToCart ||
        this.isOutofStockRef.value ||
        !this.allowAttach ||
        this.disableOrderByWeight ||
        this.checkZeroPrice
      );
    },
    packaging_size() {
      return this.skusSelected &&
        this.skusSelected.uom !== 'KG' &&
        this.skusSelected.unit_amount &&
        this.skusSelected.uom
        ? this.skusSelected.unit_amount + ' ' + this.skusSelected.uom
        : '';
    },
    colorGrayQuoteBtn() {
      return !this.allowAttach ? true : false;
    },
    priceKgBelowTotalPrice() {
      return `${priceFormatter(
        this.currencySymbol,
        displayPrice(this.skusSelected?.price / this.skusSelected?.weight)
      )}/kg`;
    },
    disabledAddQuantity() {
      if (this.isBackOrder || this.remainingQuantity === null) {
        return false;
      } else {
        return (
          this.orderQuantity >= MAX_INPUT ||
          this.orderQuantity >= this.remainingQuantity ||
          this.remainingQuantity <= 0 ||
          this.skusSelected.is_out_of_stock
        );
      }
    },

    isBackOrder() {
      if (this.skusSelected && this.skusSelected.sku_id !== 0) {
        return this.skusSelected.is_back_order;
      }
      return null;
    },
    quantity() {
      return (
        this.skusSelected && this.skusSelected.sku_id !== 0 && this.skusSelected.total_available_quantity
      );
    },
    stock() {
      return this.skusSelected && this.skusSelected.sku_id !== 0 && this.skusSelected.total_stock_location;
    },

    isQuatityAndStock() {
      let data = null;
      this.quantity === null && this.stock === null
        ? (data = null)
        : this.quantity === 0 && this.stock === 0
        ? (data = false)
        : this.quantity > 0 || this.stock > 0
        ? (data = true)
        : (data = null);
      return data;
    },

    checkDisableAddToCart() {
      return !isQuantityAMultipleOfIncrement({
        quantity: this.orderQuantity,
        increment: this.skusSelected?.increment_qty
      });
    },

    isDisableFeedBack() {
      return this.skusSelected && this.skusSelected.sku_id !== 0 ? false : true;
    },
    isDirectPrice() {
      return (
        this.skusSelected &&
        this.skusSelected.sku_id !== 0 &&
        this.skusSelected.direct_price > 0 &&
        this.skusSelected.direct_price === this.skusSelected.price
      );
    },
    checkZeroPrice() {
      return this.skusSelected.price <= 0;
    },
    remainingQuantity() {
      if (
        this.skusSelected.total_available_quantity < 0 ||
        this.skusSelected.total_available_quantity === null
      ) {
        return MAX_INPUT;
      } else {
        return this.skusSelected?.total_available_quantity ?? MAX_INPUT;
      }
    },
    isSpecialPrice() {
      return (
        this.skusSelected &&
        this.skusSelected.sku_id !== 0 &&
        this.skusSelected.direct_price > this.skusSelected.price
      );
    },
    isShowDirectPriceQuoted() {
      return this.skusSelected && this.skusSelected.sku_id !== 0 && this.skusSelected.direct_price > 0;
    },
    showSimiliarProduct() {
      return (
        this.isOutofStockRef &&
        (this.skusSelected?.sku_id !== 0 || this.skusSelected !== null) &&
        this.specsSelected?.length > 0
      );
    },
    formattedWeight() {
      return this.orderWeight;
    },
    getSimiliarProductsData() {
      return this.similiarProductItems;
    }
  },

  methods: {
    ...mapActionsCart([ACTIONS_CART.ADD_ITEM_TO_CART, ACTIONS_CART.GET_CART_ITEMS]),
    ...mapActionsNotification([ACTIONS_NOTIFICATION.GET_BADGE_NUMBERS]),
    ...mapActionsProduct([ACTIONS_SALE.SALE_UPDATE_PRICE, ACTIONS_SALE.UPDATE_SEARCH]),
    ...mapMutationsProduct([MUTATIONS.UPDATE_PRICE_WITHOUT_RELOAD, MUTATIONS.RESET_CATEGORY]),
    ...mapActionsOos([
      ACTION_OOS.GET_SALE_PRODUCTS_RECOMMEND,
      ACTION_OOS.RESET_PRODUCT_RECOMMEND,
      ACTION_OOS.UPDATE_SEARCH
    ]),

    onViewWillEnter(el) {
      el.classList.add('slide-in-right-enter');
    },
    onViewWillLeave(el) {
      el.classList.add('slide-out-right-leave');
    },

    async getSaleProductDetail() {
      await this[ACTION_OOS.RESET_PRODUCT_RECOMMEND]();
      this.user = await this.$storage.getUser();
      this.buyer = await this.$storage.getSelectedCompany();
      this.tenantId = this.user.tenant.id;
      this.buyerId = this.buyer.id;
      const { data, error } = await this.getProductDetail({
        productId: +this.productId,
        tenantId: this.tenantId,
        buyerId: this.buyerId
      });
      if (error.value) {
        await this.$router.push({ path: '/sale/main/product-detail/error' });
        return;
      }
      this.item = data;
      await Promise.all([this.$storage.getSelectedCompany()]).then((values) => {
        this.selectedCompany = values[0];
      });
      this.defaultSpecsSelected();

      // set oos status
      this.setProductOutOfStock(this.item.skusData.every((ele) => ele.is_out_of_stock));

      if (this.skusSelected) {
        this.orderWeight = this.orderWeight = this.skusSelected.weight.toFixed(2);
        if (!this.skusSelected.is_back_order && this.skusSelected.total_available_quantity > 0) {
          this.orderWeight =
            this.skusSelected.weight > this.skusSelected.total_available_quantity
              ? this.skusSelected.total_available_quantity.toFixed(2)
              : this.skusSelected.weight.toFixed(2);
        }
      } else {
        this.orderWeight = 0;
      }
      this.isShow = true;
      await this.handleDisplayShowMore();
      await this.handleReduceFontSize();
    },
    handleReduceFontSize() {
      const el = this.$refs.refNameProduct || null;
      if (el) {
        if (this.$refs.refNameProduct.$el.scrollHeight > this.height) {
          this.fontSize = this.fontSize - 3;
          this.lineHeight = this.lineHeight - 3;
          this.height = this.height - 6;
        } else {
          this.opacity = 1;
          this.isShow = false;
          this.$nextTick(() => {
            this.isShow = true;
          });
        }
      }
    },
    onInputWeight: function (event) {
      const maxWeight = 999999.99;
      let value = event.target.value;
      const decimalIndex = value.indexOf('.');
      if (decimalIndex !== -1 && value.slice(decimalIndex + 1).length > 2) {
        // truncate to two decimal places
        value = Math.trunc(parseFloat(value) * 100) / 100;
        event.target.value = value;
      }
      this.orderWeight = value;
      if (!this.isBackOrder && this.orderWeight > this.remainingQuantity) {
        this.orderWeight = this.remainingQuantity.toFixed(2);
        event.target.value = this.orderWeight;
        this.openToast(this.$t('out_of_max_weight', { weight: this.remainingQuantity.toFixed(2) }), 'danger');
      } else {
        if (this.formattedWeight > maxWeight) {
          this.openToast(this.$t('out_of_max_weight', { weight: maxWeight }), 'danger');
          this.orderWeight = maxWeight.toFixed(2);
          event.target.value = this.orderWeight;
        }
      }
    },
    handleShowHeader: debounce(function (event) {
      event.detail.scrollTop > 270 ? (this.isShowHeader = true) : (this.isShowHeader = false);
    }, 100),
    handleShowMore() {
      this.isShowMore = !this.isShowMore;
    },
    handleDisplayShowMore: debounce(function () {
      // add debounce 500 ms to wait until the all page view and ref is already rendered
      const el = this.$refs.refDescription || null;
      if (!el) return;
      this.$refs.refDescription.$el.scrollHeight > 48
        ? (this.isDisplayShowMore = true)
        : (this.isDisplayShowMore = false);
    }, 500),
    minusQuantity() {
      if (this.isOutofStockRef.value) {
        return;
      }
      const quantity = Number(this.orderQuantity) || 0;
      const increment = Number(this.skusSelected.increment_qty);

      if (quantity <= increment) {
        this.orderQuantity = increment;
      } else if (
        isQuantityAMultipleOfIncrement({
          quantity: quantity,
          increment: increment
        })
      ) {
        this.orderQuantity -= increment;
      } else {
        this.orderQuantity -= quantity % increment;
      }
    },

    addQuantity() {
      if (this.isOutofStockRef.value) {
        return;
      }
      const quantity = Number(this.orderQuantity) || 0;
      const increment = Number(this.skusSelected.increment_qty);
      if (isLargerMaxInput(quantity + increment)) {
        this.orderQuantity = MAX_INPUT;
      } else {
        if (!this.isBackOrder) {
          if (quantity < this.remainingQuantity) {
            this.orderQuantity = quantity + increment - (quantity % increment);
          } else {
            this.openToast(this.$t('out_of_max_quantity', { quantity: this.remainingQuantity }), 'danger');
            this.orderQuantity = this.remainingQuantity - (this.remainingQuantity % increment);
          }
        } else {
          this.orderQuantity = quantity + increment - (quantity % increment);
        }
      }
    },
    orderQuantityChange() {
      // process check input value
      if (!this.orderQuantity) return;

      this.orderQuantity = Number(`${this.orderQuantity}`.replace(/[^\d]/g, '')) || 0;
      if (isLargerMaxInput(this.orderQuantity)) {
        this.orderQuantity = MAX_INPUT;
      } else {
        if (!this.isBackOrder && this.orderQuantity > this.remainingQuantity) {
          const increment = Number(this.skusSelected.increment_qty);
          this.openToast(this.$t('out_of_max_quantity', { quantity: this.remainingQuantity }), 'danger');
          this.orderQuantity = this.remainingQuantity - (this.remainingQuantity % increment);
        }
      }
      this.orderQuantity = `${this.orderQuantity}`;
    },
    updateSkusSelectedById(id) {
      if (id === 0) {
        this.changeSkusSelected();
      } else {
        const index = this.item.skusData.findIndex((skus) => skus.sku_id == id);
        let data = {};
        if (index !== -1) {
          data = {
            ...this.item.skusData[index],
            image: this.item.image
          };
        }
        this.changeSkusSelected(data);
      }
    },

    async showAlert({ header, message }) {
      const alert = await alertController.create({
        mode: 'ios',
        header,
        message,
        buttons: [this.$t('close')]
      });
      setTimeout(async () => await alert.present(), 200);
    },
    async handleRefresh(event) {
      await this.detectConnection();
      event.target.complete();
    },
    detectConnection() {
      const connection = navigator.onLine;
      if (connection) {
        this.isOnline = true;
      } else {
        this.isOnline = false;
      }
    },
    async showAlertErrPrice() {
      const alert = await alertController.create({
        header: 'Oops!',
        mode: 'ios',
        message: this.$t('something_went_wrong_please_try_again'),
        buttons: [
          {
            text: this.$t('OK'),
            handler: async () => {
              await this.detectConnection();
              await this.handleGetCustomerDetail();
            }
          }
        ]
      });
      await alert.present();
    },
    async handleGetCustomerDetail() {
      try {
        const { data } = await apolloClient.query({
          query: saleGetCustomerDetail,
          variables: {
            id: this.selectedCompany.id
          }
        });
        await this.$storage.setSelectedCompany({
          ...this.selectedCompany,
          account_number: data.saleGetCustomerDetail.account_number
        });
        const updateCompany = await this.$storage.getSelectedCompany();
        this.selectedCompany = updateCompany;
      } catch (error) {
        console.log(error);
      }
    },
    async setOtherVariantModal(value) {
      this.isOpenSelectOtherVariant = value;
    },

    backHome() {
      this.router.back();
    },

    async handleCheckLatetestPrice() {
      await this.getLatestPrice();
      await this.addToCart();
    },

    // add item to cart
    async addToCart() {
      CleverTap.onUserAddToCart({ data: this.item, sku: this.skusSelected, orderQty: this.orderQuantity });

      if (this.skusSelected.price !== this.latesNewPrice[0].price) {
        this.setOpenModalPriceUpdate(true);
      } else {
        if (this.isAddToCartLoading) return false;
        this.isAddToCartLoading = true;
        const cart =
          this.cart && this.cart.items.length !== 0
            ? this.cart
            : {
                items: [],
                delivery_date: getTomorrow(),
                delivery_time: '',
                // description: '',
                // po_number: '',
                standalone: 0
              };
        const item = {
          sku_id: this.skusSelected.sku_id,
          is_favorite: this.skusSelected.is_favorite,
          quotation_status: this.skusSelected.quotation_status,
          supplier_id: this.selectedCompany.id
        };

        item.order_qty = this.skusSelected.is_order_by_weight
          ? INPUT_QUANTITY_BY_WEIGHT
          : parseInt(this.orderQuantity);
        item.order_weight = this.orderWeight;
        item.quotation_status = this.skusSelected.quotation_status;
        item.sku_id = this.skusSelected.sku_id;
        item.is_favorite = this.skusSelected.is_favorite;
        item.selected = true;
        delete item.total_available_quantity;
        const params = {
          customer_id: this.selectedCompany.id,
          delivery_date: cart.delivery_date,
          delivery_time: cart.delivery_time,
          // description: cart.description,
          // po_number: cart.po_number,
          standalone: cart.standalone === 1 ? true : false,
          item
        };

        await this[ACTIONS_CART.ADD_ITEM_TO_CART](params);
        this[ACTIONS_NOTIFICATION.GET_BADGE_NUMBERS]({
          customerId: this.selectedCompany.id
        });

        this.openToast(this.$t('successfully_added_to_cart'));
        if (this.isOpenSelectOtherVariant === true) {
          this.isOpenSelectOtherVariant = false;
        }
        this.isAddToCartLoading = false;
        this.$router.replace({ path: '/sale/main/home' });
      }
    },

    //Check latest items/product price
    async getLatestPrice() {
      try {
        const { data } = await apolloClient.query({
          query: saleGetLatestPrice,
          variables: {
            skuIds: this.skusSelected.sku_id,
            accountNumber: this.selectedCompany.account_number
          }
        });
        this.latesNewPrice = data.saleGetLatestPrice;
      } catch (error) {
        this.showAlertErrPrice();
      }
    },

    //show price changes alert
    async setOpenModalPriceUpdate(params) {
      this.isOpenPriceUpdated = params;
      if (!params) {
        await this.getSaleProductDetail();
        await this.updateSkusSelectedById(this.skusSelected.sku_id);
      }
    },

    // update local favorite - change skusSelected
    updateLocalFavorite() {
      const newFavorite = !this.skusSelected.is_favorite;
      this.changeSkusSelected({
        ...this.skusSelected,
        is_favorite: newFavorite
      });

      this.item.skusData.forEach((skus) => {
        if (skus.sku_id == this.skusSelected.sku_id) {
          skus.is_favorite = newFavorite;
        }
      });
      //logic ticket mer-207
      this.isChangeSkuFavorite = true;
      this.refreshFavoriteList();
    },

    // default if has only skusData
    defaultSpecsSelected() {
      if (this.item.skusData.length === 1 && !this.item.skusData[0].specs) {
        this.changeSkusSelected({ ...this.item.skusData[0], image: this.item.image });
        const parseSpecs = { Single_Variant: true };
        this.specsSelected = Object.values(parseSpecs);
        this.specsInvolved = Object.values(parseSpecs);
      }
    },

    // click checked-spec and update list specs selected
    unCheckSpecItem(spec) {
      const list = this.getSkusInfo;
      const index = this.specsSelected.indexOf(spec);
      if (index !== -1) {
        // remove 1 spec -> length - 1
        this.specsSelected.splice(index, 1);
        const { specsInvolved, skuIdsInvolved } = functions.getSpecsInvolvedBySpecs(this.specsSelected, list);
        this.specsInvolved = specsInvolved;
        this.skuIdsInvolved = skuIdsInvolved;

        // check if specsInvolved lenght = specsSelected length -> clear specsSelected
        if (this.specsSelected.length + 1 === this.specsInvolved.length) {
          this.specsSelected = [];
          const { specsInvolved, skuIdsInvolved } = functions.getSpecsInvolvedBySpecs(
            this.specsSelected,
            list
          );
          this.specsInvolved = specsInvolved;
          this.skuIdsInvolved = skuIdsInvolved;
        }
      }
      this.updateSkusSelectedById(0);
    },

    // click uncheck-spec and update list specs selected
    checkSpecItem(value) {
      const specs = [...this.specsSelected, value];
      const list = this.getSkusInfo;
      const { skuIds, specsSelected } = functions.findSkuFromSpecs(specs, list);
      if (skuIds.length === 1) {
        this.updateSkusSelectedById(skuIds[0]);
        this.specsSelected = specsSelected;
        this.specsInvolved = specsSelected;
        this.skuIdsInvolved = skuIds;
      } else {
        this.specsSelected = specs;
        const { specsInvolved, skuIdsInvolved } = functions.getSpecsInvolvedBySpecs(specs, list);
        this.specsInvolved = specsInvolved;
        this.skuIdsInvolved = skuIdsInvolved;
      }
    },
    showOosAndBuyAgainStatus(value) {
      const list = this.getSkusInfo;
      const result = {
        showOos: true,
        showBuyAgain: false
      };
      if (!this.specsInvolved.length || this.specsInvolved.includes(value)) {
        list.forEach((item) => {
          if (
            (!this.skuIdsInvolved.length || this.skuIdsInvolved.includes(item.skuId)) &&
            item.specs.includes(value) &&
            !item.isOos
          ) {
            result.showOos = false;
          }
          if (
            !result.showOos &&
            item.isPurchased &&
            item.specs.includes(value) &&
            (!this.skuIdsInvolved.length || this.skuIdsInvolved.includes(item.skuId))
          )
            result.showBuyAgain = true;
        });
      } else {
        result.showOos = false;
      }

      return result;
    },
    scrollToSimiliarProducts() {
      const contentElement = this.$refs.productDetailContent.$el;
      const similarProductElement = this.$refs.similiarProduct;

      if (contentElement && similarProductElement) {
        const targetOffsetTop = similarProductElement.offsetTop - 65;
        const targetOffsetLeft = similarProductElement.offsetLeft;

        contentElement.scrollToPoint(targetOffsetLeft, targetOffsetTop, 500);
      }
    },
    async reloadProductDetails() {
      const { data } = await this.getProductDetail({
        productId: this.productId,
        tenantId: this.tenantId,
        buyerId: this.buyerId,
        skuIds: this.skuIds
      });
      this.item = data;
      this.defaultSpecsSelected();
      if (this.skusSelected) {
        this.orderWeight = this.skusSelected.weight.toFixed(2);
        if (!this.skusSelected.is_back_order && this.skusSelected.total_available_quantity > 0) {
          this.orderWeight =
            this.skusSelected.weight > this.skusSelected.total_available_quantity
              ? this.skusSelected.total_available_quantity.toFixed(2)
              : this.skusSelected.weight.toFixed(2);
        }
      } else {
        this.orderWeight = 0;
      }
    },
    async setSearchParamsBackToDefault() {
      await this[MUTATIONS.RESET_CATEGORY]();
      await this[ACTIONS_SALE.UPDATE_SEARCH]({
        search: {
          ...this.search,
          searchQueries: '',
          mainCategoryId: 'history',
          sortBy: 'purchased',
          sortType: 'desc',
          categories: [],
          offset: 0
        },
        type: TYPE_SEARCH.CATEGORY
      });
    },
    async onSelectSimilarProduct(item) {
      CleverTap.onUserViewRecommendProduct(item);

      // reset oosList
      await this[MUTATIONS.RESET_PRODUCT_RECOMMEND]();

      this.$router.push({
        path: `/sale/main/product-detail/${item.id}`
      });
    },
    async onExploreOtherProducts() {
      this.specsSelected = [];
      this.specsInvolved = [];
      this.skusSelected = null;
      this.isOutofStockRef = false;
      this.$router.push({ path: '/sale/main/home' });
    },
    async openSocialSharing() {
      const deviceInfo = await Device.getInfo();
      CleverTap.onUserShareProduct(this.item);
      await this.Share.share({
        title: this.$t('product_detail.sharing_title', { product_name: this.item.name }),
        text: this.$t('product_detail.sharing_text', { product_name: this.item.name }),
        url: `${process.env.VUE_APP_PRODUCT_SHARING_URL}/?url=main/product-detail/${this.item.id}`,
        dialogTitle: this.$t('product_detail.sharing_dialog_title')
      }).then(() => {
        CleverTap.onUserShareProductSuccess(deviceInfo);
      });
    }
  },
  watch: {
    specsSelected() {
      const element = document.querySelector(`#product-details-${this.item?.id}`) || null;
      if (element) {
        const scrollHeight = element?.scrollHeight ?? 0;
        element.scrollToPoint(0, scrollHeight, 1000);
      }
    },
    async skusSelected() {
      await this[ACTION_OOS.RESET_PRODUCT_RECOMMEND]();
      if (this.skusSelected && this.skusSelected.sku_id !== 0) {
        this.skusSelected?.is_out_of_stock ? this.setOutOfStock(true) : this.setOutOfStock(false);
        if (this.isOutofStockRef) {
          this.checkOtherVariation = true;
          if (this.specsSelected !== [] && this.skusSelected !== null) {
            const url = new URL(window.history.state.current, window.location.origin);
            const skuIds = url.searchParams.getAll('skuIds');
            //manipulate the current query on url/path to update the tab value
            this.$router.replace({
              path: window.location.pathname,
              query: {
                skuIds: skuIds,
                tab: this.item.categories?.main_categories?.id
              }
            });
          }
          this.oosVariable.mainCategoryId = this.search.mainCategoryId;
          this.oosVariable.isSingleVariant = this.item.is_single_variant;
          this.oosVariable.productId = this.item.id;
          this.oosVariable.tenantId = this.item.tenant_id;
          this.oosVariable.buyerId = this.selectedCompany.id;
          await this[ACTION_OOS.GET_SALE_PRODUCTS_RECOMMEND](this.oosVariable);
          setTimeout(() => {
            this.checkOtherVariation = false;
          }, 1000);
        }
        //logic ticket mer-207
        this.isChangeSkuFavorite
          ? (this.isChangeSkuFavorite = false)
          : this.setOrderQuantity(this.skusSelected.increment_qty);
      } else {
        this.setOutOfStock(false);
      }
    },
    async isProductOutOfStockRef() {
      if (this.isProductOutOfStockRef) {
        this.checkOtherVariation = true;
        this.oosVariable.mainCategoryId = this.selectedTab;
        this.oosVariable.isSingleVariant = this.item.is_single_variant;
        this.oosVariable.productId = this.item.id;
        this.oosVariable.tenantId = this.item.tenant_id;
        this.oosVariable.buyerId = this.selectedCompany.id;
        await this[ACTION_OOS.GET_SALE_PRODUCTS_RECOMMEND](this.oosVariable);
        setTimeout(() => {
          this.checkOtherVariation = false;
        }, 1000);
      }
    },
    oosList() {
      this.similiarProductItems = this.oosList?.oosList?.items;
      if (
        (this.showSimiliarProduct || this.isProductOutOfStockRef) &&
        this.similiarProductItems?.length > 0
      ) {
        this.scrollToSimiliarProducts();
      }
    },
    fontSize() {
      setTimeout(() => {
        this.handleReduceFontSize();
      }, 50);
    },
    $ionIsActive(isActive) {
      const reloadProductDetails = async () => {
        const { data } = await this.getProductDetail({
          productId: this.productId,
          tenantId: this.tenantId,
          userId: this.buyerId,
          skuIds: this.skuIds
        });
        this.item = data;
        this.defaultSpecsSelected();
        if (this.skusSelected) {
          this.orderWeight = this.skusSelected.weight.toFixed(2);
          if (!this.skusSelected.is_back_order && this.skusSelected.total_available_quantity > 0) {
            this.orderWeight =
              this.skusSelected.weight > this.skusSelected.total_available_quantity
                ? this.skusSelected.total_available_quantity.toFixed(2)
                : this.skusSelected.weight.toFixed(2);
          }
        } else {
          this.orderWeight = 0;
        }
      };
      if (isActive) {
        reloadProductDetails(isActive);
      }
    }
  }
});
</script>
<style src="./style.scss" lang="scss" scoped></style>
